<template>
    <div class="indexPage">
        <div class="swiperBox" id="swiper" :style="{height: scrollerHeight}">
             <el-carousel>
                <el-carousel-item v-for="(item,index) in ppttrack" :key="index">
                    <a :href="item.url" target="_blank" v-if="item.type==2">
                        <img class="bannImage" id="imagBox" :src="item.image" alt="" @click="addUrl(item)">
                    </a>
                    <img v-else class="bannImage" id="imagBox" :src="item.image" alt="" @click="addUrl(item)">
                </el-carousel-item>
            </el-carousel>
        </div>

        <div class="trackBox">
            <div class="track_title">
                <h3 class="t_h3">三大赛道</h3>
                <h5 class="t_h5">THREE TYPES</h5>
            </div>
            <ul class="track_div">
                <li class="track_l_one">
                    <a href="https://www.ibeisai.com/PPThome?tagId=1006&tag=2&itemName=%E9%AB%98%E6%95%99%E4%B8%BB%E8%B5%9B%E9%81%93&type=1" target="_blank">
                    </a>
                </li>
                <li class="track_l_two">
                    <a href="https://www.ibeisai.com/PPThome?tagId=1008&tag=2&itemName=%E8%81%8C%E6%95%99%E8%B5%9B%E9%81%93&type=1" target="_blank"></a>
                </li>
                <li class="track_l_three">
                    <a href="https://www.ibeisai.com/PPThome?tagId=1007&tag=2&itemName=%E9%9D%92%E7%BA%A2%E6%97%85%E8%B5%9B%E9%81%93&type=1" target="_blank"></a>
                </li>
            </ul>
        </div>
        <div class="albumBox" style="display:none;">
            <div class="track_title">
                <h3 class="t_h3">三大赛道</h3>
                <h5 class="t_h5">THREE TYPES</h5>
            </div>
            <ul class="album_content clearfix">
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1006&tag=2&itemName=%E9%AB%98%E6%95%99%E4%B8%BB%E8%B5%9B%E9%81%93&type=1" target="_blank" rel="noopener noreferrer">
                        <img src="./../../assets/gaojiao.png" alt="">
                    </a>  
                </li>
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1008&tag=2&itemName=%E8%81%8C%E6%95%99%E8%B5%9B%E9%81%93&type=1" target="_blank">
                        <img src="./../../assets/zhijiao.png" alt="">
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1007&tag=2&itemName=%E9%9D%92%E7%BA%A2%E6%97%85%E8%B5%9B%E9%81%93&type=1" target="_blank">
                        <img src="./../../assets/qinghong.png" alt="">
                    </a>
                </li>
            </ul>
        </div>

        <!-- 精选推荐 -->
        <div class="albumBox">
            <ul class="album_content0 clearfix">
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1502&tag=3&itemName=%E6%96%B0%E5%86%9C%E7%A7%91&type=1" target="_blank">
                        <img src="./../../assets/nongke.png" alt="">
                        <div class="bg">
                            <span>新农科</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1504&tag=3&itemName=%E6%96%B0%E5%B7%A5%E7%A7%91&type=1" target="_blank">
                        <img src="./../../assets/gongke.png" alt="">
                        <div class="bg">
                            <span>新工科</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1505&tag=3&itemName=%E6%96%B0%E6%96%87%E7%A7%91&type=1" target="_blank">
                        <img src="./../../assets/wenke.png" alt="">
                        <div class="bg">
                            <span>新文科</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1507&tag=3&itemName=%E6%96%B0%E5%8C%BB%E7%A7%91&type=1" target="_blank">
                        <img src="./../../assets/yike.png" alt="">
                        <div class="bg">
                            <span>新医科</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1510&tag=3&itemName=%E5%88%9B%E6%96%B0%E7%B1%BB&type=1" target="_blank">
                        <img src="./../../assets/chuangxin.png" alt="">
                        <div class="bg">
                            <span>创新类</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1511&tag=3&itemName=%E5%95%86%E4%B8%9A%E7%B1%BB&type=1" target="_blank">
                        <img src="./../../assets/shangye.png" alt="">
                        <div class="bg">
                            <span>商业类</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/PPThome?tagId=1512&tag=3&itemName=%E5%B7%A5%E5%8C%A0%E7%B1%BB&type=1" target="_blank">
                        <img src="./../../assets/gongjiang.png" alt="">
                        <div class="bg">
                            <span>工匠类</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>

        <!-- 精选推荐 -->
        <div class="albumBox">
            <div class="track_title">
                <h3 class="t_h3">精选推荐</h3>
                <h5 class="t_h5">FEATURED ALBUM</h5>
            </div>
            <ul class="album_content clearfix album_content1">
                <li>
                    <a href="https://www.ibeisai.com/SearchPage?keyword=%E7%A7%91%E5%88%9B&select=1" target="_blank">
                        <img src="./../../assets/kechuang.png" alt="">
                        <div class="bg">
                            <span>科创类</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/SearchPage?keyword=%E6%96%87%E5%88%9B&select=1" target="_blank">
                        <img src="./../../assets/wenchaung.png" alt="">
                        <div class="bg">
                            <span>文创类</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/SearchPage?keyword=%E7%8E%AF%E4%BF%9D&select=1" target="_blank">
                        <img src="./../../assets/huanbao.png" alt="">
                        <div class="bg">
                            <span>环保类</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/SearchPage?keyword=%E5%86%9C%E4%B8%9A&select=1" target="_blank">
                        <img src="./../../assets/nongye.png" alt="">
                        <div class="bg">
                            <span>农业类</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="https://www.ibeisai.com/SearchPage?keyword=%E6%99%BA%E8%83%BD&select=1" target="_blank">
                        <img src="./../../assets/zhineng.png" alt="">
                        <div class="bg">
                            <span>智能类</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>

        <!-- 精选PPT推荐 -->
        <div class="album_PPT">
            <div class="track_title">
                <h3 class="t_h3">精品PPT推荐</h3>
                <h5 class="t_h5">PPT RECOMMEND</h5>
            </div>
            <div class="ppt_bOX">
                <article class="ppt_post" v-for="(item,index) in artiList.slice(0,12)" :key="index">
                    <div class="ppt_inner" @click="jump(1,item)">
                        <div class="ppt_media-pic">
                            <i class="ppt_tag-vip ppt_tag-vips"></i>
                            <img :src="item.cover" width="600" height="400" alt="" class="wp-ppt_post-image">
                            <a class="ppt_post-cate">
                                <em class="ppt_tag-213">
                                    演示版
                                </em>
                            </a>
                        </div>
                        <div class="clearfix">
                            <p class="ppt_post-date fl">
                                <span class="" v-for="(citem,cindex) in item.tag.split(',')" :key="cindex">
                                    <span class="ppt_mix_back_span"  >
                                        {{citem}}
                                    </span>
                                </span>
                            </p>
                        </div>
                        <div class="ppt_media-body clearfix">
                            <a class="ppt_title fl" :title="item.name">
                                <span class="ppt_title_span" v-show="item.animation!=0">{{item.animation==1?'路演':item.animation==2?'网评':''}}</span>
                                {{item.name}}
                            </a>
                            <p class="ppt_post-meta fr">
                                <em>
                                    <i class="iconfont iconai-eye"></i>{{item.pv}}
                                </em>
                                <em>
                                    <i class="iconfont iconaixin"></i>{{item.laud}}
                                </em>
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>

        <!-- 精品WORD推荐 -->
        <div class="album_WORD">
            <div class="track_title">
                <h3 class="t_h3">精品WORD推荐</h3>
                <h5 class="t_h5">WORD RECOMMEND</h5>
            </div>
            <div class="ppt_bOX">
                <article class="ppt_post ppt_posts" v-for="(item,index) in wordList.slice(0,12)" :key="'info-'+ index">
                    <div class="ppt_wode_iner" @click="jump(2,item)">
                        <i class="ppt_tag-vip"></i>
                        <img :src="item.cover" alt="">
                        <a class="ppt_post-cate">
                            <em class="ppt_tag-213">
                                文档版
                            </em>
                        </a>
                        <div class="ppt_flex-ccj">
                            <a :title="item.name">{{item.name}}</a>
                            <div class="ppt_flex-ca clearfix">
                                <span class="fl" v-for="(citem,cindex) in item.tag.split(',')" :key="cindex">
                                    <span class="ppt_mix_back_span"  >
                                        {{citem}}
                                    </span>
                                </span>
                            </div>
                            <div class="ppt_flex_div fl">
                                <em>
                                    <i class="iconfont iconai-eye"></i>{{item.pv}}
                                </em>
                                <em>
                                    <i class="iconfont iconaixin"></i>{{item.laud}}
                                </em>
                            </div>
                            <div class="ppt_mix_back">
                                <!--  -->
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        
        <div class="diversions">
            <div class="banxin">
                <p class="banxin_title"></p>
                <div class="banxin_div">
                    <vue-seamless-scroll :data='realData' :class-option="classOption1" ref="seamlessScroll" class="seamless-warp" style="padding-left:30px">
                        <ul>
                            <li class="bar_item fl" v-for="(item,index) in realData" :key="'r' + index" :data-index="index">
                                <div class="userImg">
                                    <img :src="item.avatar" alt="">
                                </div>
                                <div class="bar_detail" :title="item.content">
                                    {{item.content}}
                                </div>
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                    <vue-seamless-scroll :data='realData2' :class-option="classOption2" ref="seamlessScroll1" class="seamless-warp" style="padding-left:60px">
                        <ul>
                            <li class="bar_item fl"  v-for="(items,index) in realData2" :key="'s' +index"  :data-index="index">
                                <div class="userImg">
                                    <img :src="items.avatar" alt="">
                                </div>
                                <div class="bar_detail">
                                    {{items.content}}
                                </div>
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                    <vue-seamless-scroll :data='realData3' :class-option="classOption3" ref="seamlessScroll2" class="seamless-warp" style="padding-left:90px">
                        <ul>
                            <li class="bar_item fl" v-for="(item,index) in realData3" :key="'t' +index"  :data-index="index">
                                <div class="userImg">
                                    <img :src="item.avatar" alt="">
                                </div>
                                <div class="bar_detail">
                                    {{item.content}}
                                </div>
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                </div>
            </div>
        </div>

        <div class="itmentBox">
            <ul class="tment_ul clearfix">
                <li>
                    <i class="iconfont iconbanquan"></i>
                    <p class="decla_title">正版承诺</p>
                    <p class="decla_intro">拒绝盗版，获设计师独家授权</p>
                </li>
                <li>
                    <i class="iconfont iconbanquan1"></i>
                    <p class="decla_title">版权保障 商用无忧</p>
                    <p class="decla_intro">对全站所有模板均拥有商用授权</p>
                </li>
                <li class="last_item">
                    <i class="iconfont icontiexinfuwu"></i>
                    <p class="decla_title">全天候 贴心服务</p>
                    <p class="decla_intro">发票支持，报销无忧<br>团队协作，院校会员可多人使用</p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState ,mapMutations} from 'vuex';
export default {
    data() {
        return {
            realData:[],
            realData2:[],
            realData3:[],
            screenWidth: document.body.clientWidth,
            scrollerHeight:678+'px',
            ppttrack:[],
            artiList:[],
            wordList:[]
        }
    },  
    computed: {
        classOption1 () {
            return {
                step: 1.2, // 数值越大速度滚动越快
                limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight:0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1200 // 单步运动停止的时间(默认值1000ms)
            }
        },
        classOption2(){
            return {
                step: 1.2, // 数值越大速度滚动越快
                limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight:0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1200 // 单步运动停止的时间(默认值1000ms)
            }
        },
        classOption3(){
            return {
                step: 1.2, // 数值越大速度滚动越快
                limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight:0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1200 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    methods: {
        getapp(){
            //获取轮播图
            this.axios.indexAd({
            }).then(res=>{
                if(res.data.code==0){
                    this.ppttrack = res.data.data;
                    this.swiperBox();
                }else{
                    this.ppttrack = []
                }
            }).catch(err=>{

            })
            this.axios.indexPage({

            }).then(res=>{
                if(res.data.code==0){
                    this.artiList = res.data.data.ppt;
                    this.wordList = res.data.data.word;
                    this.swiperBox();
                }
            }).catch(err=>{

            })
        },
        getJob(){
            //获取首页评论
            this.axios.indexMsg({

            }).then(res=>{
                if(res.data.code==0){
                    this.realData = res.data.data.s1
                    this.$refs.seamlessScroll.reset();
                    this.realData2 = res.data.data.s2
                    this.$refs.seamlessScroll1.reset();
                    this.realData3 = res.data.data.s3
                    this.$refs.seamlessScroll2.reset();
                }
            }).catch(err=>{

            })
        },
        //跳转至详情页
        jump(val,item){
            let link = this.$router.resolve({name:'Detailspage',query:{type:val,code:item.code}})
            window.open(link.href,'_blank')
        },
        swiperBox(){
            const that = this
            var height =  document.getElementById('imagBox').offsetHeight;
            that.scrollerHeight = height + 'px'   
        },
        addUrl(item){
            console.log(item)
        },
        ...mapMutations(['UPP_BOOLE'])
    },
    watch:{
        screenWidth(val){
            this.swiperBox()
        }
    },
    created(){
        this.getJob()
        this.getapp()
    },
    mounted() {
        this.UPP_BOOLE()
        this.getapp()
        this.$nextTick(() => {
            setTimeout(()=>{
               
                this.swiperBox()
            },1000)
        })
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.screenWidth = window.screenWidth
            })()
        }
    }
}
</script>
<style scoped>
@import "./../../styles/Page/indexPage.css";
</style>
<style>
    .seamless-warp{
        height: 80px;
        margin-bottom: 17px;
        overflow: hidden;
    }
    .swiperBox .el-carousel__indicators{
        opacity: 0;
    }
    .swiperBox .el-carousel{
        width: 100%;
        overflow: hidden;
        height: 100%;
    }
    .swiperBox .el-carousel__container{
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
</style>